import { useTheme } from "@emotion/react";
import Background from "../molecules/Background/Background";
import ContactBlocks from "../organisms/ContactBlocks";
import ProjectList from "../organisms/ProjectList";
import useWindowDimensions from "../../assets/hook/WindowsDimensionsHook";

function HomePage() {
    const theme = useTheme();
    const sizes = useWindowDimensions();
    
    return (
        <>
            <Background></Background>
            <main>
                <div style={{
                    marginBottom: '30px',
                    color: theme.palette.mode === 'dark' ? theme.palette.white : theme.palette.secondary.main, 
                }}>
                    <h2 style={{
                        margin:'10px 0 0 0',
                        fontWeight: 400,
                        fontSize:  (sizes.width > 900 ? '30px' : '18px')
                    }}>MOSTACCI Grégory</h2>
                    <h1 style={{
                        margin:'0',
                        fontSize: (sizes.width > 900 ? '60px' : '22px')
                        }}>Développeur web</h1>
                    <h2 style={{
                        margin:'0',
                        fontSize:  (sizes.width > 900 ? '30px' : '18px')
                    }}>Chef de projet junior</h2>
                    <h2 style={{
                        margin:'0',
                        fontWeight: 400,
                        fontSize:  (sizes.width > 900 ? '20px' : '15px')
                    }}>Objectif: Gestion de produit</h2>
                </div>
                <p style={{
                    maxWidth: "1000px",
                    textAlign: "justify",
                    marginBottom: "40px",
                    fontSize: (sizes.width > 900 ? '15px' : '13px')
                }}>Diplomé d'un Mastère Expert en développement WEB, je me suis formé aux technologies de ce milieu (PHP, SQL, Cloud, JS). Après cinq années de développement web et trois années de gestion de projets lors de mon cursus d’apprentissage, je souhaite allier ces compétences pour mener à bien des projets informatiques dans le monde professionnel. Mon objectif est de découvrir et d’apprendre de nouveaux concepts tout en étant proche du client final.</p>
                <ContactBlocks></ContactBlocks>
                <ProjectList></ProjectList>
            </main>
        </>
        
    );
}

export default HomePage;
